export function cloneCollectionAndUpdateOrAppendItemById<T extends { id: any }>(
	collectionToClone: T[] | null | undefined,
	itemToReplace: T,
): T[] {
	const clone = [ ...collectionToClone ?? [] ];

	if (!('id' in itemToReplace))
		throw new Error('itemToReplace must have an id property');

	const entityArrayIndex = clone.findIndex(v => itemToReplace.id === v.id);

	if (entityArrayIndex === -1)
		clone.push(itemToReplace);
	else
		clone.splice(entityArrayIndex, 1, itemToReplace);

	return clone;
}
