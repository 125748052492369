import { MetadataEntity } from '../metadata-entity';

import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

type AliasForOptions = {
	serializeAliasSourceProperty?: boolean;
	serializeAliasProperty?: boolean;
};

/**
 * On construction a value for the property is taken from DTO[alias]
 * On serialization the json will contain an alias property with a value equal the instance property value
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function AliasFor(
	aliasForPropertyName: string,
	options: AliasForOptions = {},
): PropertyMetadataMapper {
	const {
		serializeAliasProperty,
		serializeAliasSourceProperty,
	}: AliasForOptions = {
		serializeAliasSourceProperty: true,
		serializeAliasProperty: true,
		...options,
	};

	return function(model: MetadataEntity, property: string): void {
		Property({
			aliasForPropertyName,
			serializeAliasSourceProperty,
			unserializable: !serializeAliasProperty,
		})(model, property);
	};
}
