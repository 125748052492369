import { Enumeration } from '@bp/shared/models/core/enum';

export class Deployment extends Enumeration {

	static readonly frontend = new Deployment();

	static readonly nest = new Deployment();

	static readonly dotnet = new Deployment();

	static readonly python = new Deployment();

	static override parseHook(value: unknown): Deployment | null {
		if (`${ value }`.toLowerCase().includes('noble'))
			return Deployment.nest;

		if (`${ value }`.toLowerCase().includes('dazzling'))
			return Deployment.dotnet;

		if (`${ value }`.toLowerCase().includes('preem'))
			return Deployment.python;

		return null;
	}

	get isBackend(): boolean {
		return this !== Deployment.frontend;
	}
}
