import { isArray as _isArray } from 'lodash-es';

/**
 * Checks if value is classified as an Array object.
 * @param value The value to check.
 *
 * @return Returns true if value is correctly classified, else false.
 */
export function isArray<T>(value: T | T[]): value is T[] {
	return _isArray(value);
}
