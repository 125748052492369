export const PAGE_SIZE = 24;

export interface IPageQueryParamsDTO {

	pageSize?: string;

	page?: string | null;

	limit?: number;

}

export interface IPageQueryParams {

	page?: string | null;

	limit: number;

}
