import { isSensitiveKey, OBSCURED_LITERAL } from '@bp/shared/models/core';
import { toPlainObject } from '@bp/shared/utilities/core';

import { MetadataEntity } from '../metadata-entity';

// eslint-disable-next-line unicorn/no-useless-undefined
export const omitSecretValuesRecursively = replaceSecretValuesRecursivelyWith(undefined);

export const obscureSecretValuesRecursively = replaceSecretValuesRecursivelyWith(OBSCURED_LITERAL);

function replaceSecretValuesRecursivelyWith<T extends object>(
	replacement: string | undefined,
): (object: T) => T {
	return object => toPlainObject(object, function(this, key, value) {
		const isSecretProperty = this instanceof MetadataEntity
			&& (this.classMetadata.get(<any>key)?.isSecret || this.classMetadata.get(<any>key)?.isLogObscured);

		return (isSecretProperty || isSensitiveKey(key)) ? replacement : value;
	});
}
