import { MapFromDTO } from './decorators';
import { MetadataEntity } from './metadata-entity';

export abstract class PrimitiveValueObject<T extends boolean | number | string> extends MetadataEntity {

	@MapFromDTO()
	abstract readonly value: T;

	constructor(valueOrValueObject: PrimitiveValueObject<T> | T) {
		super({
			value: valueOrValueObject instanceof PrimitiveValueObject
				? valueOrValueObject.value
				: valueOrValueObject,
		});
	}

	override toString(): string {
		return this.value.toString();
	}

	override valueOf(): T {
		return this.value;
	}

	toJSON(): T {
		return this.value;
	}

}
