// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const requestIdleCallback: typeof globalThis.requestIdleCallback = globalThis.requestIdleCallback || function(handler, options) {
	const startTime = Date.now();

	return setTimeout(
		() => void handler({
			didTimeout: false,
			timeRemaining: () => Math.max(0, 50 - (Date.now() - startTime)),
		}),
		options?.timeout ?? 1,
	);
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
export const cancelIdleCallback: typeof globalThis.cancelIdleCallback = globalThis.cancelIdleCallback || function(id) {
	clearTimeout(id);
};
