import { isObject, isString, mapValues } from 'lodash-es';

/**
 * Parses values of the objects;
 */
export function objectNormalize(value: object[] | object): object[] | object {
	return Array.isArray(value)
		? value.map(objectNormalize)
		: (isObject(value)
			? mapValues(value, parse)
			: value);
}

function parse(value: any): any {
	try {
		if (isString(value))
			return <object>JSON.parse(value);
	} catch { }

	return value;
}
