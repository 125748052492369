import { MetadataQueryParamsBase } from './query-params-base';

export function assertIsMetadataQueryParamsBased(
	query: unknown,
): asserts query is MetadataQueryParamsBase {
	if (query instanceof MetadataQueryParamsBase)
		return;

	throw new Error('Provided value is not query params based');
}
