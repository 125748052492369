import { IPageQueryParams } from './query-params';

export class RecordsPage<TEntity = any, TDto = any> {

	readonly lastSyncTimestamp?: number;

	readonly nextSyncTimestamp?: number;

	readonly nextPageCursor: string | null = null;

	readonly firstPage: boolean | null = null;

	readonly records!: TEntity[];

	constructor(data: RecordsPage<TEntity>);
	constructor(
		data: RecordsPage<TDto> | TDto[],
		factory: (dto: TDto) => TEntity
	);
	constructor(
		query: IPageQueryParams | undefined,
		data: RecordsPage<TDto>,
		factory: (dto: TDto) => TEntity
	);
	constructor(
		query: IPageQueryParams | undefined,
		data: RecordsPage<TDto>,
		factory: (dto: TDto) => TEntity
	);
	constructor(
		queryOrData?: IPageQueryParams | RecordsPage<TDto> | TDto[],
		dataOrFactory?: RecordsPage<TDto> | TDto[] | ((dto: TDto) => TEntity),
		factory?: (dto: TDto) => TEntity,
	) {
		if (queryOrData && 'records' in queryOrData) {
			Object.assign(this, queryOrData);

			if (!dataOrFactory)
				return;
		}

		if (!dataOrFactory)
			throw new Error('Invalid arguments');

		if (Array.isArray(queryOrData) && dataOrFactory instanceof Function) {
			this.records = queryOrData.map(dataOrFactory);

			return;
		}

		if (queryOrData && 'records' in queryOrData && dataOrFactory instanceof Function) {
			this.records = queryOrData.records.map(dataOrFactory);

			return;
		}

		if ('records' in dataOrFactory && 'page' in queryOrData!) {
			this.nextPageCursor = dataOrFactory.nextPageCursor;

			this.firstPage = !queryOrData.page;

			this.records = dataOrFactory.records.map(factory!);

			this.lastSyncTimestamp = dataOrFactory.lastSyncTimestamp;

			this.nextSyncTimestamp = dataOrFactory.nextSyncTimestamp;
		}
	}

}
