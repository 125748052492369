import { assign } from 'lodash-es';

import { Type } from '@angular/core';
import { SortDirection } from '@angular/material/sort';

import { Constructable, PrimitiveConstructable } from '@bp/shared/models/core';
import { Enumeration } from '@bp/shared/models/core/enum';
import { sentenceCase } from '@bp/shared/utilities/core';

import { FieldViewType } from './enums';
import { PropertyMetadataControl } from './property-metadata-control';
import { PropertyMetadataTable } from './property-metadata-table';

export type PropertyMapperFunction = (propertyDTO: any, instanceDTO: any, thisInstance: any) => any;

export type PropertyMapper = PropertyMapperFunction
| Type<Constructable>
| Type<Enumeration>
| Type<PrimitiveConstructable>
| typeof Constructable
| typeof Enumeration
| typeof PrimitiveConstructable;

export class PropertyMetadata {

	readonly label?: string | null;

	/**
	 * On construction a value for the property is taken from DTO[alias]
	 * On deserialization the json will contain an alias property with a value equal the instance property value
	 */
	readonly aliasForPropertyName?: string | null;

	readonly serializeAliasSourceProperty?: boolean;

	readonly serializeAliasProperty?: boolean;

	readonly isLogObscured: boolean = false;

	readonly hint: string = '';

	readonly longHint: string = '';

	readonly mapper: PropertyMapper | null = null;

	readonly defaultPropertyValue?: unknown | (() => unknown);

	readonly control = new PropertyMetadataControl();

	readonly viewType = FieldViewType.text;

	readonly viewFormatter: ((propertyValue: any) => any) | null = null;

	readonly viewEmptyValue: number | string | null = null;

	/**
	 * If true, the property and its label will be displayed only if it is present in the DTO
	 */
	readonly viewOnlyIfPresent: boolean = false;

	readonly table: PropertyMetadataTable | null = null;

	readonly sortable: boolean = false;

	readonly defaultSortField: boolean = false;

	readonly defaultSortDir: SortDirection = 'desc';

	readonly unserializable: boolean = false;

	readonly sourceOfDTO: boolean = false;

	readonly copyable: boolean = false;

	readonly underDevelopment: boolean = false;

	readonly isSecret: boolean = false;

	readonly symbolize: string | null = null;

	/**
	 * The name of the property to which this metadata belongs
	 */
	readonly property!: string;

	readonly displayPropertyName: string;

	constructor(data: Partial<PropertyMetadata>) {
		assign(this, data);

		this.displayPropertyName = sentenceCase(this.property);

		this.label = this.label !== undefined && this.label !== this.displayPropertyName
			? this.label
			: this.displayPropertyName;
	}

	toString(): string {
		return this.label!;
	}

	valueOf(): any {
		return this.property;
	}

}
