
import { v4 as uuidv4 } from 'uuid';

export function uuid({ prefix, short = false }: { prefix?: string; short?: boolean } = {}): string {
	let id = uuidv4();

	id = short ? id.slice(0, 6) : id;

	return prefix ? `${ prefix }${ id }` : id;
}
