import type { MetadataEntity } from '../metadata-entity';
import { PropertyMetadataControl } from '../property-metadata-control';

export function $$classMetadataPropertyMetadataControlInjector(
	model: MetadataEntity,
	property: string,
	config?: Partial<PropertyMetadataControl<unknown>>,
): void {
	const propertyMetadata = model.classMetadata.get(<any>property);
	const currentControlConfig = propertyMetadata ? propertyMetadata.control : {};

	const control = new PropertyMetadataControl<unknown>({
		...currentControlConfig,
		...config,
	});

	model.classMetadata
		.add(property, { control });
}
