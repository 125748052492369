import { camelCase, isNumber, isObject, isString, upperFirst } from 'lodash-es';
import { titleCase as toTitleCase } from 'title-case';
import moment, { Moment, MomentInput } from 'moment';

import { Enumeration } from '@bp/shared/models/core/enum';
import { isEmpty } from '@bp/shared/utilities/core';

export function booleanMapper(value: boolean | string): boolean {
	return isString(value)
		? value.toLowerCase() === 'true'
		: value;
}

export function pascalCase(value: Enumeration | string | undefined): string {
	return upperFirst(camelCase(isObject(value) ? value.toString() : value));
}

export function titleCase(v: Enumeration | string | undefined): string {
	return v
		? toTitleCase(isObject(v) ? v.toString()! : v)
		: '';
}

export function camelCaseArray(value: string[]): string[] {
	return value.map(camelCase);
}

export function lowerCase(value: string): string {
	return value.toLowerCase();
}

export function trimMapper(value: string): string {
	return value.trim();
}

export function uriComponentObjectMapper(value: unknown): Record<string, unknown> {
	return isString(value)
		? JSON.parse(decodeURIComponent(value))
		: value;
}

export function numberMapper(value: unknown): number {
	if (isNumber(value))
		return value;

	const numberValue = Number(value);

	return Number.isNaN(numberValue) ? 0 : numberValue;
}

export function momentMapper(momentInput: MomentInput): Moment {
	if (isNumber(momentInput) || isString(momentInput) && !Number.isNaN(Number(momentInput))) {
		const isSeconds = momentInput.toString().length === 10;
		const epoch = Number(momentInput);

		return moment.unix(isSeconds ? epoch : epoch / 1000);
	}

	return moment(momentInput);
}

export function hostNameFromUrlMapper(v: string): string {
	if (isEmpty(v))
		return '';

	// we need 'https://' at the start otherwise 'new URL(v)' will not parse it
	v = v.replace(/^(https?:\/\/)?/ui, 'https://');

	try {
		return new URL(v).hostname;
	} catch {
		return '';
	}
}
