import { isEmpty } from 'lodash-es';

import { EmptyObject } from '@bp/shared/typings';

/**
 * Checks if value is not empty. A value is considered not empty when it’s an arguments object, array, string,
 * or jQuery-like collection with a length greater than 0 or an object with own enumerable properties.
 */
export function isNotEmpty<T>(value: EmptyObject | T | '' | 0 | null | undefined): value is T {
	return !isEmpty(value);
}
