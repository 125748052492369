import { Enumeration } from './enum';
import type { IDescribable } from './interfaces';

export abstract class DescribableEnumeration extends Enumeration implements IDescribable {
	constructor(
		displayName?: string,
		public readonly description?: string,
	) {
		super(displayName);
	}
}
