import { identity } from 'lodash-es';
import { firstValueFrom } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

import type { QueryList } from '@angular/core';

export async function firstPresentQueryListItem<T, U = T>(
	source: QueryList<T>,
	mapper: (source: T) => U = identity,
): Promise<U> {
	return firstValueFrom(source.changes
		.pipe(
			startWith<QueryList<T>>(source),
			map(queryList => queryList.first),
			first(queryListItem => !!queryListItem),
			map(queryListItem => mapper(queryListItem)),
		));
}
