/* eslint-disable max-classes-per-file */
import { assign, isEqual } from 'lodash-es';

export class Size {
	constructor(public width: number, public height: number) { }
}

export interface IPosition {
	top?: number;
	left?: number;
	bottom?: number;
	right?: number;
}

export class Position {
	top!: number;

	bottom!: number;

	left!: number;

	right!: number;

	constructor(position?: Partial<Position>) {
		position && assign(this, position);
	}

	equal(d: Position) {
		return isEqual(this, d);
	}
}

export class Dimensions extends Size implements IPosition {
	top = 0;

	left = 0;

	get bottom() {
		return this.top + this.height;
	}

	get right() {
		return this.left + this.width;
	}

	constructor(dimensions?: Partial<Dimensions>) {
		super(dimensions?.width ?? 0, dimensions?.height ?? 0);

		if (dimensions) {
			this.top = dimensions.top ?? 0;

			this.left = dimensions.left ?? 0;
		}
	}

	equal(d: Dimensions) {
		return isEqual(this, d);
	}
}
