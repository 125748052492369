import { Ability } from '@bp/shared/models/core';

import { FieldViewType } from '../enums';
import type { MetadataEntity } from '../metadata-entity';

import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function View(viewType: FieldViewType, viewFormatter?: (propertyValue: any) => any): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		if (viewType === FieldViewType.booleanCircle)
			viewFormatter ??= v => v ? Ability.enabled : Ability.disabled;

		Property({ viewType, viewFormatter })(model, property);
	};
}
