import { isFunction } from 'lodash-es';

export function isExtensionOf<T extends Function>(targetType: T, ancestorType: T): boolean {
	if (!isFunction(targetType) || !isFunction(ancestorType))
		throw new Error('isExtensionOf accepts only functions');

	do {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		targetType = Object.getPrototypeOf(targetType);

		if (targetType === ancestorType)
			return true;
	}
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	while (targetType);

	return false;
}
