import type { AbstractControlOptions, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import type { PrimitiveConstructable } from '@bp/shared/models/core';
import type { Enumeration } from '@bp/shared/models/core/enum';
import type { Dictionary, GetArrayType, NonFunctionPropertyNames } from '@bp/shared/typings';

import type { MetadataEntity } from './metadata-entity';

export type FormSchemeArray<T> = readonly ['array', FormScheme<T>];

export type FormSchemeAbstractControlOptions = AbstractControlOptions & { defaultValue?: unknown };

export type FormScheme<T> = {
	[ K in NonFunctionPropertyNames<T> ]?: T[ K ] extends MetadataEntity[]
		? FormSchemeArray<GetArrayType<T[ K ]>>
		: T[ K ] extends MetadataEntity
			? FormScheme<T[ K ]> | null
			: FormSchemeAbstractControlOptions | ValidatorFn | ValidatorFn[] | null
};

export type MetadataEntityFormControls<T> = {
	[ K in NonFunctionPropertyNames<T> ]: T[ K ] extends MetadataEntity[]
		? UntypedFormArray
		: T[ K ] extends (any[] | Enumeration | PrimitiveConstructable)
			? UntypedFormControl
			: T[ K ] extends (Dictionary<any> | MetadataEntity)
				? UntypedFormGroup
				: UntypedFormControl
};

export function ensureFormScheme<T>(scheme: FormScheme<T>): FormScheme<T> {
	return scheme;
}
