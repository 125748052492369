import type { MetadataEntity } from '../metadata-entity';

import { $$classMetadataPropertyMetadataControlInjector } from './class-metadata-property-metadata-control-injector';
import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

/**
 * Such property is hidden from telemetry
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Secret(): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		Property({ isSecret: true })(model, property);

		$$classMetadataPropertyMetadataControlInjector(
			model,
			property,
			{ isSecret: true },
		);
	};
}
