import { snakeCase } from 'lodash-es';
import { SnakeCasedPropertiesDeep } from 'type-fest';

import { mapKeysDeep } from './map-keys-deep';

export function toSnakeCaseKeys<T extends object>(object: T): SnakeCasedPropertiesDeep<T> {
	return <SnakeCasedPropertiesDeep<T>>mapKeysDeep(
		object,
		(_value, key) => key
			.split('.')
			.map(snakeCase)
			.join('.'),
	);
}
