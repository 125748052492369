import { isMap } from 'lodash-es';

import { isObjectLike } from './lodash-wrappers';
import { transformMapToObject } from './transform-map-to-object';

type StringifyReplacer = (this: unknown, propertyName: string, propertyValue: unknown) => unknown;

export function jsonStringifyCircularSafe(target: object, replacer?: StringifyReplacer): string {
	return JSON.stringify(
		target,
		buildGeneralStringifyReplacer(replacer),
	);
}

/**
 * - Converts all map properties to objects
 * - Removes cyclic objects
 */
export function toPlainObject<T extends object>(target: T, replacer?: StringifyReplacer): T {
	return JSON.parse(jsonStringifyCircularSafe(target, replacer));
}

function buildGeneralStringifyReplacer(replacer?: StringifyReplacer): StringifyReplacer {
	const seen = new WeakSet();

	return function(this, propertyName, propertyValue) {
		if (isObjectLike(propertyValue)) {
			if (seen.has(propertyValue))
				return;

			seen.add(propertyValue);
		}

		if (isMap(propertyValue))
			return transformMapToObject(propertyValue);

		return replacer ? replacer.bind(this, propertyName, propertyValue)() : propertyValue;
	};
}
