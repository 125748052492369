import { Moment } from 'moment';

import { Constructor } from '@bp/shared/typings';

import { Mapper, View, Default, Label } from '../decorators';
import { FieldViewType } from '../enums';
import { momentMapper } from '../mappers';

import { EntityBase } from './entity-base';

export type EntityCtor = Constructor<Entity>;

export abstract class Entity extends EntityBase {

	@Label('Created at')
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	created!: Moment | null;

	@Label('Modified at')
	@Mapper(momentMapper)
	@View(FieldViewType.momentFromNow)
	@Default(null)
	modified!: Moment | null;

}
