import { camelCase, startCase } from 'lodash-es';
import { PascalCasedPropertiesDeep } from 'type-fest';

import { mapKeysDeep } from './map-keys-deep';

export function toPascalCaseKeys<T extends object>(object: T): PascalCasedPropertiesDeep<T> {
	return <PascalCasedPropertiesDeep<T>>mapKeysDeep(
		object, (_value, key) => startCase(camelCase(key)).replace(/ /ug, ''),
	);
}
