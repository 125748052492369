import { SortDirection } from '@angular/material/sort';

export const SORT_FIELD = 'sortField';

export interface ISortQueryParams<TSortField = string> {

	[SORT_FIELD]?: TSortField;

	/**
	 * @default descending
	 */
	sortDir?: SortDirection;

}

export interface ISortQueryParamsDTO<TSortField = string> extends ISortQueryParams<TSortField> { }
