export enum ResponseStatusCode {
	NoResponse = 0,
	Ok = 200,
	Created = 201,
	NoContent = 204,
	Redirect = 302,
	SeeOther = 303,
	BadRequest = 400,
	Unauthorized = 401,
	TransactionDeclined = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	Timeout = 408,
	Conflict = 409,
	UnprocessableEntity = 422,
	RateLimited = 429,
	InternalServerError = 500,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	ConnectionTimeout = 522,
}

export const RESPONSE_STATUS_CODE_MESSAGES = {
	// 2XX
	[ResponseStatusCode.Ok]: 'Ok',
	[ResponseStatusCode.Created]: 'Created',
	[ResponseStatusCode.NoContent]: 'No Content',

	// 3XX
	[ResponseStatusCode.Redirect]: 'Redirect',
	[ResponseStatusCode.SeeOther]: 'See Other',

	// 4XX
	[ResponseStatusCode.BadRequest]: 'Bad request',
	[ResponseStatusCode.Unauthorized]: 'Unauthorized',
	[ResponseStatusCode.TransactionDeclined]: 'Transaction declined',
	[ResponseStatusCode.Forbidden]: 'Access forbidden',
	[ResponseStatusCode.NotFound]: 'Not found',
	[ResponseStatusCode.MethodNotAllowed]: 'Method Not Allowed',
	[ResponseStatusCode.RateLimited]: 'You are being rate limited',
	[ResponseStatusCode.Timeout]: 'Operation timed out',
	[ResponseStatusCode.Conflict]: 'Conflict',
	[ResponseStatusCode.UnprocessableEntity]: 'Unprocessable Entity',

	// 5XX
	[ResponseStatusCode.ConnectionTimeout]: 'Connection timed out',
	[ResponseStatusCode.GatewayTimeout]: 'Gateway Timeout',
	[ResponseStatusCode.ServiceUnavailable]: 'Service Unavailable',
	[ResponseStatusCode.InternalServerError]: 'Internal Server Error',

	// 0
	[ResponseStatusCode.NoResponse]: 'No Response',
};
