import type { Moment } from 'moment';

import { TrackByFunction } from '@angular/core';

import { Enumeration } from '@bp/shared/models/core/enum';
import { TextMaskConfigInput } from '@bp/shared/features/text-mask';
import { Country } from '@bp/shared/models/countries';

export type InputBasedControlOptions = {

	/**
	 * @default 'text'
	 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
	 */
	inputmode?: 'decimal' | 'email' | 'numeric' | 'search' | 'tel' | 'text' | 'url';
	maxLength?: number;
	textMask?: TextMaskConfigInput;
	hideClearButton?: boolean;
};

export type PhoneControlOptions = {
	initialDialCodeCountry: Country;
};

export type ChipControlOptions = {
	resetOptionText?: string;
	trackByFn?: TrackByFunction<any>;
};

export type CountryControlOptions = {
	hasWorldwide: boolean;
	hideClearButton?: boolean;
};

export type AutocompleteControlOptions = {
	useItemValueOf?: boolean;
	suggestedItem?: any;
	suggestedItemTooltip?: string;
	suggestedItemButtonTextPrefix?: string;
};

export type SelectControlOptions = {
	multiple?: boolean;
};

export class FieldControlType<T = unknown> extends Enumeration {
	// General
	static input = new FieldControlType<InputBasedControlOptions>({
		inputmode: 'text',
	});

	static number = new FieldControlType<InputBasedControlOptions>({
		inputmode: 'decimal',
	});

	static email = new FieldControlType<InputBasedControlOptions>({
		inputmode: 'email',
	});

	static url = new FieldControlType<InputBasedControlOptions>({
		inputmode: 'url',
	});

	static phone = new FieldControlType<PhoneControlOptions>();

	static password = new FieldControlType<InputBasedControlOptions>();

	static textarea = new FieldControlType<InputBasedControlOptions>();

	static inputChips = new FieldControlType();

	static numberChips = new FieldControlType();

	static switch = new FieldControlType();

	static checkbox = new FieldControlType();

	static datetime = new FieldControlType();

	static date = new FieldControlType<{
		min?: Moment;
		max?: Moment;
		startAt?: Moment;
		startView?: 'month' | 'multi-year' | 'year';
	}>();

	static time = new FieldControlType();

	static select = new FieldControlType<SelectControlOptions>();

	static autocomplete = new FieldControlType<AutocompleteControlOptions>();

	static buttonToggle = new FieldControlType();

	static chip = new FieldControlType<ChipControlOptions>();

	static currency = new FieldControlType();

	static country = new FieldControlType<CountryControlOptions>();

	static countryChips = new FieldControlType<ChipControlOptions>();

	static brand = new FieldControlType();

	static brandChips = new FieldControlType<ChipControlOptions>();

	static inputLike = [
		FieldControlType.input,
		FieldControlType.textarea,
		FieldControlType.number,
		FieldControlType.email,
		FieldControlType.url,
	];

	static chipLike = [
		FieldControlType.chip,
		FieldControlType.brandChips,
		FieldControlType.countryChips,
	];

	static inputChipLike = [
		FieldControlType.inputChips,
		FieldControlType.numberChips,
	];

	static autocompleteLike = [
		...FieldControlType.chipLike,
		FieldControlType.autocomplete,
	];

	static override parseHook(value: unknown): FieldControlType | null {
		if (`${ value }`.toLowerCase() === 'textarea')
			return FieldControlType.textarea;

		return null;
	}

	options?: T;

	get isSelect(): boolean {
		return this === FieldControlType.select;
	}

	get isInput(): boolean {
		return this === FieldControlType.input;
	}

	constructor(options?: T) {
		super();

		this.options = options;
	}
}
