/**
 * A class which is used with the isExtensionOf function to determine
 * if a value is a primitive constructable function
 */
export abstract class PrimitiveConstructable<TId extends string = string> {

	protected static readonly _cache = new Map<string, any>();

	private get _cacheKey(): string {
		return `${ this._getScope() }#${ this._id }`;
	}

	constructor(protected _id: TId) {
		this._assertIdIsPresent();

		if (this._isCached())
			return PrimitiveConstructable._cache.get(this._cacheKey)!;
	}

	private _assertIdIsPresent(): void {
		if (!this._id)
			throw new Error('PrimitiveConstructable inheritor must provide an id to the super constructor method');
	}

	protected abstract _getScope(): string;

	protected _isCached(): boolean {
		return PrimitiveConstructable._cache.has(this._cacheKey);
	}

	protected _cacheAndFreezeInstance(): void {
		PrimitiveConstructable._cache.set(this._cacheKey, this);

		Object.freeze(this);
	}

	toString(): string {
		return this._id;
	}

	valueOf(): string {
		return this._id;
	}

	toJSON(): string {
		return this._id;
	}

}
