import { mapKeys } from 'lodash-es';

import {
	IValidationErrors, IValidatorFunc, ValidationError, Validators
} from '@bp/shared/features/validation/models';

import { MetadataEntity } from './metadata-entity';
import { PropertyMetadata } from './property-metadata';
import { PrimitiveValueObject } from './primitive-value-object';

export const METADATA_OBJECT_VALIDATOR = Symbol('METADATA_OBJECT_VALIDATOR');

export type IMetadataObjectValidatorFunc = () => Record<string, IValidationErrors> | null;

export interface IValidatableMetadataObject extends MetadataEntity {
	[METADATA_OBJECT_VALIDATOR]: IMetadataObjectValidatorFunc;
}

export class MetadataObjectValidator {

	validate(model: MetadataEntity, currentPath?: string): void {
		const propertiesErrors = model.classMetadata.values
			.filter(({ property }) => !this.__isAliasedProperty(property, model))
			.reduce<Record<string, IValidationErrors> | null>(
				(errors, propertyMetadata) => {
					const propertyErrors = this.__executeAndReturnErrors(() => {
						this.__checkRequiredValue(model, propertyMetadata);

						this.__checkControlValidators(model, propertyMetadata);

						this.__checkIncomingListValueIsInAllowedListValues(model, propertyMetadata);

						this.__checkNestedMetadata(model, propertyMetadata);
					});

					return propertyErrors ? { ...errors, ...propertyErrors } : errors;
				},
				null,
			);

		try {
			if (propertiesErrors !== null)
				throw new ValidationError(undefined, propertiesErrors);

			if (this.__isValidatableMetadataObject(model))
				this.__validateObject(model);
		} catch (error: unknown) {
			if (!(error instanceof ValidationError) || !currentPath)
				throw error;

			throw model instanceof PrimitiveValueObject
				? new ValidationError(
					undefined,
					mapKeys(error.validationErrors, () => currentPath),
				)
				: new ValidationError(
					undefined,
					mapKeys(
						error.validationErrors,
						(_value, path) => `${ currentPath }.${ path }`,
					),
				);
		}
	}

	private __executeAndReturnErrors(callback: () => void): Record<string, IValidationErrors> | null {
		try {
			callback();

			return null;
		} catch (error: unknown) {
			if (!(error instanceof ValidationError))
				throw error;

			return error.validationErrors!;
		}
	}

	private __checkRequiredValue(model: MetadataEntity, propertyMetadata: PropertyMetadata): void {
		if (!propertyMetadata.control.required)
			return;

		this.__assertPropertyValid(
			this.__getPropertyPath(model, propertyMetadata),
			Validators.required,
			model[<keyof MetadataEntity>propertyMetadata.property],
		);
	}

	private __checkControlValidators(model: MetadataEntity, propertyMetadata: PropertyMetadata): void {
		if (!propertyMetadata.control.validator)
			return;

		this.__assertPropertyValid(
			this.__getPropertyPath(model, propertyMetadata),
			propertyMetadata.control.validator,
			model[<keyof MetadataEntity>propertyMetadata.property],
		);
	}

	private __checkIncomingListValueIsInAllowedListValues(
		model: MetadataEntity, propertyMetadata: PropertyMetadata,
	): void {
		if (propertyMetadata.control.list.length === 0)
			return;

		const modelValueOrValues = model[<keyof MetadataEntity>propertyMetadata.property];
		const modelValues = Array.isArray(modelValueOrValues) ? modelValueOrValues : [ modelValueOrValues ];
		const validator = Validators.inList(propertyMetadata.control.list);
		const propertyPath = this.__getPropertyPath(model, propertyMetadata);

		modelValues.forEach(value => void this.__assertPropertyValid(propertyPath, validator, value));
	}

	private __checkNestedMetadata(
		model: MetadataEntity, propertyMetadata: PropertyMetadata,
	): void {
		const valueOrValues = model[<keyof MetadataEntity>propertyMetadata.property];
		const propertyPath = this.__getPropertyPath(model, propertyMetadata);

		if (Array.isArray(valueOrValues)) {
			valueOrValues.forEach((value: any, index: number) => {
				if (value instanceof MetadataEntity)
					this.validate(value, `${ propertyPath }[${ index }]`);
			});
		} else if (valueOrValues instanceof MetadataEntity)
			this.validate(valueOrValues, propertyPath);
	}

	private __assertPropertyValid(
		propertyPath: string, validator: IValidatorFunc, value: unknown,
	): void {
		const validationResult = validator({ value });

		if (validationResult !== null)
			throw new ValidationError(undefined, { [propertyPath]: validationResult });
	}

	private __isValidatableMetadataObject(model: MetadataEntity): model is IValidatableMetadataObject {
		return METADATA_OBJECT_VALIDATOR in model && typeof model[METADATA_OBJECT_VALIDATOR] === 'function';
	}

	private __validateObject(model: IValidatableMetadataObject): void {
		const validationResult = model[METADATA_OBJECT_VALIDATOR]();

		if (validationResult !== null)
			throw new ValidationError(undefined, validationResult);
	}

	private __isAliasedProperty(property: string, model: MetadataEntity): boolean {
		return model.classMetadata.values.some(
			propertyMetadata => propertyMetadata.aliasForPropertyName === property,
		);
	}

	private __getPropertyPath(model: MetadataEntity, propertyMetadata: PropertyMetadata): string {
		return propertyMetadata.aliasForPropertyName && model.hasPropertyInInstanceDto(propertyMetadata.aliasForPropertyName)
			? propertyMetadata.aliasForPropertyName
			: propertyMetadata.property;
	}

}
