/**
 * https://devblogs.microsoft.com/typescript/announcing-typescript-5-2/#:~:text=because%20this%20feature%20is%20so%20recent%2C%20most%20runtimes%20will%20not%20support%20it%20natively.%20to%20use%20it%2C%20you%20will%20need%20runtime%20polyfills%20for%20the%20following
 */

// @ts-expect-error polyfill
Symbol.dispose ??= Symbol('Symbol.dispose');

// @ts-expect-error polyfill
Symbol.asyncDispose ??= Symbol('Symbol.asyncDispose');

export {};
