import type { MetadataEntity } from '../metadata-entity';

import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ViewOnlyIfPresent(): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		Property({ viewOnlyIfPresent: true })(model, property);
	};
}
