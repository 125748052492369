import { Constructor } from '@bp/shared/typings';

import { Default, Mapper } from '../decorators';
import { booleanMapper } from '../mappers';

import { IdentifiableEntity } from './identifiable-entity';

export type EntityBaseCtor = Constructor<EntityBase>;

export abstract class EntityBase extends IdentifiableEntity {

	@Default(null)
	name!: string | null;

	@Mapper(booleanMapper)
	@Default(null)
	isEnabled!: boolean | null;

	override toString(): string {
		return this.name ?? 'No Name';
	}
}
