import { identity, sortBy } from 'lodash-es';

export function sortByArray<TSourceItem, TByArrayItem>(
	source: TSourceItem[],
	byArray: TByArrayItem[],
	sourceItemToByArrayItemTransformer: (item: TSourceItem) => TByArrayItem = identity,
): TSourceItem[] {

	const indexesByElements = new Map(byArray.map((item, index) => [ item, index ]));

	const orderedResult = sortBy(
		source,
		item => indexesByElements.get(sourceItemToByArrayItemTransformer(item)),
	);

	return orderedResult;
}
