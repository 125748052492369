import { IClassMetadataConfig } from '../class-metadata';
import type { MetadataEntity } from '../metadata-entity';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ClassMetadataConfig(config: IClassMetadataConfig) {
	return function classMetadataBuilder<T extends typeof MetadataEntity>(constructor: T): void {
		constructor
			.getClassMetadata()
			.setConfig(config);
	};
}
