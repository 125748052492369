import type { Type } from '@angular/core';

export function isInstanceOf<T>(type: Type<T>) {
	return (value: unknown): value is T => value instanceof type;
}

export function isInstanceOfSome<T extends (abstract new (...args: any) => any)[]>(...types: T) {
	return (value: unknown): value is (T[number] extends infer U
		? U extends (abstract new (...args: any) => infer R)
			? R : never
		: never) => types.some(type => value instanceof type);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isInstanceOfAbstract<T>(type: any) {
	return (value: unknown): value is T => value instanceof type;
}
