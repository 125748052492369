export function buildUrl(baseUrl: string, urlSearchParams: ConstructorParameters<typeof URLSearchParams>[0]): string {
	const url = new URL(baseUrl);

	url.search = new URLSearchParams([
		...url.searchParams.entries(),
		...new URLSearchParams(urlSearchParams).entries(),
	])
		.toString();

	return url.toString();
}
