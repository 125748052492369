import { Default } from '../decorators';
import { DTO } from '../dto-metadata-entity-type';
import { MetadataEntity } from '../metadata-entity';

export abstract class IdentifiableEntity extends MetadataEntity {
	@Default(null)
	id!: string | null;

	constructor(dto?: DTO<IdentifiableEntity>) {
		super(dto);
	}
}
