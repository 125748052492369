import { SortDirection } from '@angular/material/sort';

import { MetadataEntity } from '../metadata-entity';

import { Property, PropertyMetadataMapper } from './property-metadata.decorator';

/**
 * Marks the property as sortable
 * @param isDefault If true, the property will be used as default sort field for entity
 * @param defaultDir The default sort direction for entity, if not provided, the default is 'desc'
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Sortable({
	defaultDir,
	isDefault,
}: { isDefault?: boolean; defaultDir?: SortDirection } = {}): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		Property({
			sortable: true,
			defaultSortDir: defaultDir ?? 'desc',
			defaultSortField: !!isDefault,
		})(model, property);
	};
}
