import { Constructor } from '@bp/shared/typings';

import { DTO } from '../dto-metadata-entity-type';
import { MetadataEntity } from '../metadata-entity';

export type MetadataQueryParamsBaseCtor<TQueryParamsDTO = any> =
	Constructor<MetadataQueryParamsBase<TQueryParamsDTO>>;

export class MetadataQueryParamsBase<TQueryParamsDTO = any> extends MetadataEntity {

	protected _dto!: TQueryParamsDTO;

	constructor(queryParamsDTO?: DTO<MetadataQueryParamsBase<TQueryParamsDTO>>) {
		super(queryParamsDTO);

		Object.defineProperty(this, '_dto', {
			enumerable: false, // By doing this the property becomes unserializable by JSON.stringify()
			value: queryParamsDTO ?? {},
		});
	}

}
