import { isFunction } from 'lodash-es';

import type { NonFunctionPropertyNames } from '@bp/shared/typings';

export function groupBy<T, U extends NonFunctionPropertyNames<T>>(
	array: T[],
	iteratee: U | ((item: T) => T[ U ]),
): Map<T[U], T[]> {
	const groupingItemIteratee = isFunction(iteratee)
		? iteratee
		: (item: T) => item[iteratee];

	return array.reduce(
		(map, item) => {
			const groupingItem = groupingItemIteratee(item);

			return map.set(groupingItem, [ ...(map.get(groupingItem) ?? []), item ]);
		},
		new Map<T[ U ], T[]>(),
	);
}
