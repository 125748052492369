import { SimpleChange as NgSimpleChange } from '@angular/core';

import type { NonFunctionProperties } from '@bp/shared/typings';

export class SimpleChange<T> extends NgSimpleChange {

	override previousValue: T | undefined;

	override currentValue: T | undefined;

}

export type SimpleChanges<T extends NonFunctionProperties<T>> = {
	[ K in keyof T ]?: T[ K ] extends never ? never : SimpleChange<T[ K ]>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface OnChanges {
	ngOnChanges: (changes: SimpleChanges<any>) => void;
}
