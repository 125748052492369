export const MASK_SOURCE_CHAR = '*';

export const MASK_SUBSTITUTION_CHAR = '●';

export function hasMaskSourceChar(value: string): boolean {
	return value.includes(MASK_SOURCE_CHAR);
}

export function hasMaskedChars(value: string): boolean {
	return value.includes(MASK_SOURCE_CHAR) || value.includes(MASK_SUBSTITUTION_CHAR);
}

export function replaceMaskSourceChars(value: string): string {
	return value.replaceAll(MASK_SOURCE_CHAR, MASK_SUBSTITUTION_CHAR);
}
