export function normalizeHtml(html: string): string {
	html = isHtmlUriEncoded(html)
		? decodeURIComponent(html.replace(/\+/ug, ' '))
		: html;

	html = isHtmlEncoded(html)
		? new DOMParser().parseFromString(html, 'text/html').documentElement.textContent ?? ''
		: html;

	if (!html.startsWith('<!DOCTYPE') && !html.startsWith('<!doctype'))
		html = `<!DOCTYPE html>${ html }`;

	if (html.includes('%3E') || html.includes('&gt;')) // > symbol
		throw new Error('The html field value contains invalid encoded characters');

	if (!html.includes('</html>'))
		throw new Error('The html field value is not a valid html');

	return html;
}

function isHtmlUriEncoded(input: string): boolean {
	return [
		'%26LT%3B%2Fhtml%26GT%3B',
		'%26lt%3b%2fhtml%26gt%3b',
		'%3C%2Fhtml%3E',
		'%3c%2fhtml%3e',
		'%26LT%3B%HTML%26GT%3B',
		'%26lt%3b%HTML%26gt%3b',
		'%3C%HTML%3E',
		'%3c%HTML%3e',
	] // </html>
		.some(v => input.includes(v));
}

function isHtmlEncoded(input: string): boolean {
	return [
		'&lt;/html&gt;',
		'&LT;/html&GT;',
		'&lt;/HTML&gt;',
		'&LT;/HTML&GT;',
	]
		.some(v => input.includes(v));
}
