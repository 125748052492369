import { identity } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import type { QueryList } from '@angular/core';

export function observeQueryListFirstItem<T, U = T>(
	observedQueryList: QueryList<T>,
	mapper: (source: T) => U = identity,
): Observable<U | null> {
	return observedQueryList.changes
		.pipe(
			startWith<QueryList<T>>(observedQueryList),
			map(queryList => queryList.first),
			map(queryListFirstItem => queryListFirstItem ? mapper(queryListFirstItem) : null),
		);
}
