
import type { MetadataEntity } from '../metadata-entity';

import { $$classMetadataPropertyMetadataControlInjector } from './class-metadata-property-metadata-control-injector';
import { PropertyMetadataMapper } from './property-metadata.decorator';

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Required(): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		$$classMetadataPropertyMetadataControlInjector(
			model,
			property,
			{ required: true },
		);
	};
}
