import { isSensitiveKey, OBSCURED_LITERAL, Sensitive } from './constants';

const jwtRegExp = new RegExp(`${ Sensitive.jwt }=([^&]*)`, 'u');
const refreshTokenRegExp = new RegExp(`${ Sensitive.refreshToken }=([^&]*)`, 'u');

export function obscureSensitiveDataInUrl(this: void, url: string): string {
	url = url.replace(jwtRegExp, `${ Sensitive.jwt }=${ OBSCURED_LITERAL }`);

	url = url.replace(refreshTokenRegExp, `${ Sensitive.refreshToken }=${ OBSCURED_LITERAL }`);

	return url;
}

export function obscureSensitiveDataInJsonString(this: void, json: string): string {
	try {
		return JSON.stringify(JSON.parse(
			json,
			obscureSensitiveDataReplacer,
		));
	} catch {
		return json;
	}
}

export function obscureSensitiveDataReplacer(this: void, key: string, value: unknown): unknown {
	if (isSensitiveKey(key))
		return OBSCURED_LITERAL;

	return value;
}
