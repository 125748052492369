import { Enumeration } from '@bp/shared/models/core/enum';
import { IEnvironmentConfig } from '@bp/shared/typings';

export class Environment extends Enumeration {

	static readonly local = new Environment();

	static readonly pullRequestPreview = new Environment();

	static readonly staging = new Environment();

	static readonly prerelease = new Environment();

	static readonly production = new Environment();

	get isLocal(): boolean {
		return this === Environment.local;
	}

	get isStaging(): boolean {
		return this === Environment.staging;
	}

	get isPullRequestPreview(): boolean {
		return this === Environment.pullRequestPreview;
	}

	get isPrerelease(): boolean {
		return this === Environment.prerelease;
	}

	get isProduction(): boolean {
		return this === Environment.production;
	}

	override get name(): IEnvironmentConfig['name'] {
		return <IEnvironmentConfig['name']> super.name;
	}
}
