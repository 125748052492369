import { camelCase, isObject, isString, set, snakeCase, transform } from 'lodash-es';

import { isArray } from '@bp/shared/utilities/core';

import { JsonNamingStrategy } from './json-naming-strategy';

export function transformKeysToCase<T extends object>(
	target: T,
	strategy: JsonNamingStrategy | `${ JsonNamingStrategy }`,
): T {
	return transform(target, (result, value: unknown, key) => {
		if (isArray(value)) {
			value = value.map(item => isArray(item) || isObject(item)
				? transformKeysToCase(item, strategy)
				: item);
		} else if (isObject(value))
			value = transformKeysToCase(value, strategy);

		if (isString(key)) {
			const newKey = strategy === JsonNamingStrategy.SnakeCase ? snakeCase(key) : camelCase(key);

			set(result, newKey, value);
		} else
			set(result, key, value);
	});
}
