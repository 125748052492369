import { IValidationErrors } from './models';

export class ValidationError extends Error {

	constructor(
		message?: string,
		public readonly validationErrors?: Record<string, IValidationErrors>,
	) {
		super(message ?? 'Validation error');
	}

}
