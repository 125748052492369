import type { MetadataEntity } from '../metadata-entity';
import type { PropertyMetadata } from '../property-metadata';

export type PropertyMetadataMapper = (model: MetadataEntity, property: string) => void;

export type PropertyDecoratorConfig = Omit<Partial<PropertyMetadata>, 'property'>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Property(config: PropertyDecoratorConfig): PropertyMetadataMapper {
	return function(model: MetadataEntity, property: string): void {
		model
			.classMetadata
			.add(property, config);
	};
}
