import { isFunction, isNil } from 'lodash-es';

import { fastdom } from './fastdom';

type SizeInfo = {
	width: number;
	height: number;
};

type OnResizeCallback = (sizeInfo: SizeInfo) => void;

type HTMLDivResetSensorElement = HTMLDivElement & { resetSensor?: Function };

type HTMLResizableElement = HTMLElement & {
	resizedAttached?: EventQueue;
	resizeSensor?: HTMLDivResetSensorElement;
};

export class ResizeSensor {
	static reset(element: HTMLResizableElement | HTMLResizableElement[]) {
		ResizeSensor._forEachElement(element, $element => $element.resizeSensor?.resetSensor?.());
	}

	static detach(element: HTMLResizableElement | HTMLResizableElement[], callback: OnResizeCallback) {
		ResizeSensor._forEachElement(element, $element => {
			if (isNil($element) || !$element.resizedAttached)
				return;

			if (isFunction(callback))
				$element.resizedAttached.remove(callback);

			if (!$element.resizeSensor || $element.resizedAttached.length > 0)
				return;

			$element.contains($element.resizeSensor) && $element.resizeSensor.remove();

			delete $element.resizeSensor;

			delete $element.resizedAttached;
		});
	}

	private static _forEachElement(
		elements: HTMLResizableElement | HTMLResizableElement[],
		callback: ($element: HTMLResizableElement) => void,
	) {
		const elementsType = Object.prototype.toString.call(elements);
		const isCollectionTyped = elementsType === '[object Array]'
			|| elementsType === '[object NodeList]'
			|| elementsType === '[object HTMLCollection]'
			|| elementsType === '[object Object]';

		if (isCollectionTyped)
			(<HTMLResizableElement[]> elements).forEach(callback);
		else
			callback(<HTMLResizableElement> elements);
	}

	private readonly _elements: HTMLResizableElement | HTMLResizableElement[];

	constructor(element: HTMLElement | HTMLElement[], onResizeCallback: OnResizeCallback) {
		this._elements = element;

		ResizeSensor._forEachElement(element, $element => void this._attachResizeEvent($element, onResizeCallback));
	}

	detach(onResizeCallback: OnResizeCallback) {
		ResizeSensor.detach(this._elements, onResizeCallback);
	}

	reset() {
		ResizeSensor.reset(this._elements);
	}

	private async _attachResizeEvent(
		$element: HTMLResizableElement,
		resized: OnResizeCallback,
	) {
		if (isNil($element))
			return;

		if ($element.resizedAttached) {
			$element.resizedAttached.add(resized);

			return;
		}

		$element.resizedAttached = new EventQueue();

		$element.resizedAttached.add(resized);

		const $resizeSensor = $element.resizeSensor = <HTMLDivResetSensorElement> document.createElement('div');

		$resizeSensor.dir = 'ltr';

		$resizeSensor.className = 'resize-sensor';

		const style = {
			pointerEvents: 'none',
			position: 'absolute',
			left: '0px',
			top: '0px',
			right: '0px',
			bottom: '0px',
			overflow: 'hidden',
			zIndex: '-1',
			visibility: 'hidden',
			maxWidth: '100%',
		};

		const styleChild = {
			position: 'absolute',
			left: '0px',
			top: '0px',
			transition: '0s',
		};

		void this._setStyle($resizeSensor, style);

		const $expand = document.createElement('div');

		$expand.className = 'resize-sensor-expand';

		void this._setStyle($expand, style);

		const $expandChild = document.createElement('div');

		void this._setStyle($expandChild, styleChild);

		$expand.append($expandChild);

		const $shrink = document.createElement('div');

		$shrink.className = 'resize-sensor-shrink';

		void this._setStyle($shrink, style);

		const $shrinkChild = document.createElement('div');

		void this._setStyle($shrinkChild, { ...styleChild, width: '200%', height: '200%' });

		$shrink.append($shrinkChild);

		$resizeSensor.append($expand);

		$resizeSensor.append($shrink);

		$element.append($resizeSensor);

		const computedStyle = await fastdom
			.measure(() => globalThis.getComputedStyle($element));

		const position = isNil(computedStyle) ? null : computedStyle.position;

		if (position !== 'absolute' && position !== 'relative' && position !== 'fixed')
			await fastdom.mutate(() => ($element.style.position = 'relative'));

		let dirty: boolean;
		let rafId: number;
		let size: SizeInfo = await this._getElementSize($element);
		let lastWidth = 0;
		let lastHeight = 0;
		let initialHiddenCheck = true;
		let lastAnimationFrame = 0;

		const resetExpandShrink = async () => {
			const { width, height } = await fastdom.measure(() => ({ width: $element.offsetWidth, height: $element.offsetHeight }));

			await fastdom.mutate(() => {
				$expandChild.style.width = `${ width + 10 }px`;

				$expandChild.style.height = `${ height + 10 }px`;

				$expand.scrollLeft = width + 10;

				$expand.scrollTop = height + 10;

				$shrink.scrollLeft = width + 10;

				$shrink.scrollTop = height + 10;
			});
		};

		const reset = async () => {
			// Check if element is hidden
			if (initialHiddenCheck) {
				const isInvisible = await fastdom.measure(() => $element.offsetWidth === 0 && $element.offsetHeight === 0);

				if (isInvisible) {
					// Check in next frame
					// eslint-disable-next-line logical-assignment-operators
					if (!lastAnimationFrame) {
						lastAnimationFrame = requestAnimationFrame(() => {
							lastAnimationFrame = 0;

							void reset();
						});
					}

					return;
				}

				// Stop checking
				// eslint-disable-next-line require-atomic-updates
				initialHiddenCheck = false;
			}

			void resetExpandShrink();
		};

		$resizeSensor.resetSensor = reset;

		const onResized = () => {
			rafId = 0;

			if (!dirty)
				return;

			lastWidth = size.width;

			lastHeight = size.height;

			$element.resizedAttached?.call(size);
		};

		const onScroll = async () => {
			size = await this._getElementSize($element);

			dirty = size.width !== lastWidth || size.height !== lastHeight;

			if (dirty && !rafId)
				rafId = requestAnimationFrame(onResized);

			void reset();
		};

		$expand.addEventListener('scroll', () => void onScroll());

		$shrink.addEventListener('scroll', () => void onScroll());

		// Fix for custom Elements
		requestAnimationFrame(() => void reset());
	}

	private async _setStyle(element: HTMLElement, style: Partial<CSSStyleDeclaration>) {
		return fastdom.mutate(() => void Object
			.keys(style)
			.map(v => <number> <unknown> v)
			.forEach(k => (element.style[k] = (<Required<CSSStyleDeclaration>> style)[k])));
	}

	private async _getElementSize(element: HTMLElement) {
		const rect = await fastdom.measure(() => element.getBoundingClientRect());

		return {
			width: Math.round(rect.width),
			height: Math.round(rect.height),
		};
	}
}

if (typeof MutationObserver !== 'undefined') {
	const observer = new MutationObserver(mutations => {
		for (const record of mutations) {
			// eslint-disable-next-line unicorn/prefer-spread
			for (const $element of <HTMLResizableElement[]> Array.from(record.addedNodes))
				$element.resizeSensor?.resetSensor?.();
		}
	});

	document.addEventListener('DOMContentLoaded', () => void observer.observe(document.body, {
		childList: true,
		subtree: true,
	}));
}

class EventQueue {
	private _queue: OnResizeCallback[] = [];

	get length() {
		return this._queue.length;
	}

	add(func: OnResizeCallback) {
		this._queue.push(func);
	}

	call(sizeInfo: SizeInfo) {
		this._queue.forEach(func => void func.call(this, sizeInfo));
	}

	remove(func: OnResizeCallback) {
		this._queue = this._queue.filter(v => v !== func);
	}
}
