import { camelCase } from 'lodash-es';
import { CamelCasedPropertiesDeep } from 'type-fest';

import { mapKeysDeep } from './map-keys-deep';

export function toCamelCaseKeys<T extends object>(object: T): CamelCasedPropertiesDeep<T> {
	return <CamelCasedPropertiesDeep<T>>mapKeysDeep(
		object,
		(_value, key) => camelCase(key),
	);
}
