import { isString } from 'lodash-es';

import { IValidationErrors, IValidatorFunc, IValidatorInput, Validators } from '@bp/shared/features/validation/models';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { MetadataEntity } from '../metadata-entity';

export function inSortableFieldsListOf(entity: typeof MetadataEntity): IValidatorFunc {
	return ({ value }: IValidatorInput): IValidationErrors | null => {
		if (Validators.isEmptyValue(value))
			return null; // Don't validate empty values

		const metadata = entity.getClassMetadata();
		const allowedSortFields = metadata.keys
			.filter(key => {
				const propertyMetadata = metadata.get(<NonFunctionPropertyNames<MetadataEntity>> key);

				return !!propertyMetadata?.sortable || !!propertyMetadata?.defaultSortField;
			});

		const validationError = {
			inSortableFieldsOf: `Value '${ value }' is not in allowed values list: ${ allowedSortFields
				.map(field => `"${ field }"`)
				.join(', ') }`,
		};

		return isString(value) && allowedSortFields.includes(value)
			? null
			: validationError;
	};
}
