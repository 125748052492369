import { assign, isString } from 'lodash-es';

import { Validators, IValidatorFunc } from '@bp/shared/features/validation/models';

import { FieldControlType } from './enums';

export class PropertyMetadataControl<TControlOptions> {

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	readonly type: FieldControlType<any> = <any>FieldControlType.input;

	readonly list: any[] = [];

	/**
	 * We don't use Validators.required because this flag is handled via required input param in mat form
	 * field component
	 */
	readonly required: boolean;

	/**
	 * A special name invoke browsers autocomplete feature
	 * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
	 */
	readonly nativeAutocomplete?: boolean | string;

	readonly validator?: IValidatorFunc<any> | null;

	readonly typeControlOptions?: TControlOptions;

	readonly placeholder: string = '';

	readonly warning: string | null = null;

	readonly controlValueTransformer?: ((propertyValue: any) => any) | null = null;

	readonly isSecret: boolean = false;

	constructor(data?: Partial<PropertyMetadataControl<TControlOptions>>) {
		assign(this, data);

		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		this.required ??= false;

		this._setDefaultMetadataBasedOnControlType();
	}

	private _setDefaultMetadataBasedOnControlType(): void {
		// eslint-disable-next-line default-case
		switch (this.type) {
			case FieldControlType.brandChips:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder ??= 'Add brand...';
				break;

			case FieldControlType.countryChips:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder ??= 'Add country...';
				break;

			case FieldControlType.chip:
				// @ts-expect-error we need to set default state of readonly prop
				this.placeholder ??= 'Add item...';
				break;

			case FieldControlType.url:
				// @ts-expect-error we need to set default state of readonly prop
				this.validator ??= Validators.url;

				// @ts-expect-error we need to set default state of readonly prop
				this.nativeAutocomplete ??= 'url';

				break;

			case FieldControlType.email:
				// @ts-expect-error we need to set default state of readonly prop
				this.validator ??= Validators.email();

				// @ts-expect-error we need to set default state of readonly prop
				this.controlValueTransformer ??= (value?: string) => value?.toLowerCase();
				break;
		}

		if (!isString(this.nativeAutocomplete) && FieldControlType.autocompleteLike.includes(this.type))
			// @ts-expect-error we need to set default state of readonly prop
			this.nativeAutocomplete = false;
	}
}
