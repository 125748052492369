import { assign, get, isArray, isString, isObject, isEmpty } from 'lodash-es';

import { HttpErrorResponse } from '@angular/common/http';

import { DeepPartial } from '@bp/shared/typings';
import {
	IApiErrorResponse, IErrorMessage, ResponseStatusCode, RESPONSE_STATUS_CODE_MESSAGES
} from '@bp/shared/models/core';
import { isHtml } from '@bp/shared/utilities/core';

type BpErrorInput = DeepPartial<BpError> | Error | ErrorEvent | HttpErrorResponse | IApiErrorResponse | PromiseRejectionEvent | string;

export class BpError<TErrorPayload = unknown> extends Error {

	private static readonly __httpBridgerpayErrorName = 'HttpBridgerpayError';

	private static readonly __generalBridgerpayErrorName = 'BridgerpayError';

	static readonly criticalErrorPrefix = '[critical]';

	static fromUnknown(error: unknown): BpError {
		return new BpError(<any>error);
	}

	static get notFound(): BpError {
		return new BpError({ status: ResponseStatusCode.NotFound });
	}

	// eslint-disable-next-line complexity
	private static __parseErrorInput(errorInput: BpErrorInput): Partial<BpError> {
		const error: Partial<BpError> = {};

		if (errorInput instanceof Error)
			error.originalError = errorInput;

		if (errorInput instanceof Error || errorInput instanceof ErrorEvent) {
			if (errorInput instanceof Error) {
				error.stack = errorInput.stack;

				error.name = errorInput.name;
			}

			error.messages = [{ message: errorInput.message }];
		} else if (errorInput instanceof PromiseRejectionEvent)
			error.messages = [{ message: `${ errorInput.reason }` }];
		else if (isString(errorInput))
			error.messages = [{ message: errorInput }];
		else if (errorInput instanceof HttpErrorResponse) {
			error.name = this.__httpBridgerpayErrorName;

			error.requestUrl = errorInput.url;

			error.status = this.__parseStatus(errorInput);

			error.statusText = get(
				RESPONSE_STATUS_CODE_MESSAGES,
				error.status,
			) || errorInput.statusText;

			if (isString(errorInput.error) && !isHtml(errorInput.error))
				error.messages = [{ message: errorInput.message }, { message: errorInput.error }];
			else if (checkIsBridgerpayHttpErrorResponsePayload(errorInput.error)) {
				error.messages = this.__tryExtractMessagesFromBpHttpErrorResponse(
					errorInput.error,
				);

				if (errorInput.error.result && !checkIsBridgerpayHttpErrors(errorInput.error.result))
					error.payload = errorInput.error.result;
			}
		} else if (isObject(errorInput) && 'response' in errorInput) {
			const errorResponse = errorInput;

			error.status = errorResponse.response.code;

			error.statusText = get(RESPONSE_STATUS_CODE_MESSAGES, error.status);

			error.messages = this.__tryExtractMessagesFromBpHttpErrorResponse(
				errorResponse,
			);

			if (errorResponse.result && !checkIsBridgerpayHttpErrors(errorResponse.result))
				error.payload = errorResponse.result;
		} else
			assign(error, errorInput);

		if (error.name === 'Error' || !error.name)
			error.name = this.__generalBridgerpayErrorName;

		error.messages = error.messages?.map(message => ({
			...message,
			type: message.type === 'validation_error' ? undefined : message.type,
		})) ?? [];

		if (isEmpty(error.messages)) {
			error.status ??= ResponseStatusCode.NoResponse;

			error.statusText ??= get(RESPONSE_STATUS_CODE_MESSAGES, error.status);

			error.messages = [{ message: error.statusText || 'Unknown error' }];
		}

		error.message ??= error.messages[0]?.message ?? error.messages[0]?.type;

		return error;
	}

	private static __tryExtractMessagesFromBpHttpErrorResponse(error: IErrorMessage | Partial<IApiErrorResponse>): IErrorMessage[] {
		if ('result' in error && error.result && checkIsBridgerpayHttpErrors(error.result)) {
			return (isArray(error.result)
				? error.result
				: [ error.result ]);
		}

		if ('response' in error && error.response?.message)
			return [{ message: error.response.message }];

		if ('message' in error && error.message)
			return [{ message: error.message }];

		return [];
	}

	private static __parseStatus(httpErrorResponse: HttpErrorResponse): ResponseStatusCode {
		return httpErrorResponse.status || ResponseStatusCode.NoResponse;
	}

	messages: IErrorMessage[] = [];

	/**
	 * Error message, or first message from the error messages array, or the status text
	 */
	override message!: string;

	status?: ResponseStatusCode;

	statusText?: string;

	requestUrl?: string | null;

	payload?: TErrorPayload;

	get isForbidden(): boolean {
		return this.status === ResponseStatusCode.Forbidden;
	}

	get isInternalServerError(): boolean {
		return this.status === ResponseStatusCode.InternalServerError;
	}

	get isTransactionDeclined(): boolean {
		return this.status === ResponseStatusCode.TransactionDeclined;
	}

	get is5XX(): boolean {
		return this.status! >= 500;
	}

	get is503(): boolean {
		return this.status === ResponseStatusCode.ServiceUnavailable;
	}

	get is502(): boolean {
		return this.status === ResponseStatusCode.GatewayTimeout;
	}

	get isTimeout(): boolean {
		return [
			ResponseStatusCode.Timeout,
			ResponseStatusCode.GatewayTimeout,
			ResponseStatusCode.ConnectionTimeout,
		].includes(this.status!);
	}

	get isNoResponse(): boolean {
		return this.status === ResponseStatusCode.NoResponse;
	}

	get isHttpError(): boolean {
		return this.name === BpError.__httpBridgerpayErrorName;
	}

	get isGeneralError(): boolean {
		return this.name === BpError.__generalBridgerpayErrorName;
	}

	originalError?: Error | HttpErrorResponse | PromiseRejectionEvent | unknown;

	constructor(errorInput: BpErrorInput, options?: ErrorOptions) {
		if (errorInput instanceof BpError)
			return errorInput;

		const error = BpError.__parseErrorInput(errorInput);

		super(error.message, options);

		assign(this, error);
	}

	override toString(): string {
		return this.message;
	}
}

function checkIsBridgerpayHttpErrorResponsePayload(payload: unknown): payload is IApiErrorResponse {
	return isObject(payload) && ('response' in payload && 'code' in (<IApiErrorResponse>payload).response || 'result' in payload);
}

function checkIsBridgerpayHttpErrors(payload: unknown): payload is IErrorMessage | IErrorMessage[] {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const sample = isArray(payload) ? payload[0] : payload;

	return isObject(sample) && ('message' in sample || 'type' in sample);
}
