import { NonFunctionProperties, NonFunctionPropertyNames, Typify } from '@bp/shared/typings';

import { PropertyMetadata } from './property-metadata';

type MetadataHost<TMetadataHostClass> = { getClassMetadata: () => ClassMetadata<TMetadataHostClass> };

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IClassMetadataConfig {

}

export class ClassMetadata<TMetadataHostClass> implements IClassMetadataConfig {

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	readonly properties: Typify<Required<NonFunctionProperties<TMetadataHostClass>>, PropertyMetadata> = <any>{};

	readonly values: PropertyMetadata[] = [];

	readonly keys: string[] = [];

	readonly defaultSortProperty?: PropertyMetadata;

	constructor(private readonly __metadataHost: MetadataHost<TMetadataHostClass>) {
		this.__initReadonlyPropertiesAfterMetadataHasBeenBuiltAndSealInstance();
	}

	add(property: string, metadata: Partial<PropertyMetadata>): void {
		// @ts-expect-error we need to assign to the property index by string here but its readonly by nature
		const propertyMetadata = this.properties[property] = new PropertyMetadata({
			...this.__getPropertyMetadataLookingUpAncestors(<any>property),
			...metadata,
			property,
		});

		this.__mirrorCriticalPropertyMetadataToAliasSourceProperty(propertyMetadata);

		this.__trySetDefaultTableSortProperty(propertyMetadata);

		this.__initReadonlyPropertiesAfterMetadataHasBeenBuiltAndSealInstance();
	}

	get(propertyName: NonFunctionPropertyNames<TMetadataHostClass>): PropertyMetadata | null {
		return <PropertyMetadata | null> this.properties[propertyName] ?? null;
	}

	has(propertyName: NonFunctionPropertyNames<TMetadataHostClass>): boolean {
		return !!(<PropertyMetadata | null> this.properties[propertyName]);
	}

	setConfig(config: IClassMetadataConfig): void {
		Object.assign(this, config);
	}

	private __getPropertyMetadataLookingUpAncestors(property: string): PropertyMetadata | null {
		return this.get(<any>property)
			?? this.__getPrototypeClassMetadata()?.__getPropertyMetadataLookingUpAncestors(property)
			?? null;
	}

	private __getPrototypeClassMetadata(): ClassMetadata<TMetadataHostClass> | undefined {
		const prototype = <MetadataHost<TMetadataHostClass>> Object.getPrototypeOf(this.__metadataHost);

		return 'getClassMetadata' in prototype
			? prototype.getClassMetadata()
			: undefined;
	}

	private __initReadonlyPropertiesAfterMetadataHasBeenBuiltAndSealInstance(): void {
		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.properties = this.__mergePrototypeAndClassPropertiesMetadata();

		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.keys = Object.keys(this.properties);

		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.values = Object.values(this.properties);

		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.defaultSortProperty ??= this.__getPrototypeClassMetadata()?.defaultSortProperty;
	}

	private __mergePrototypeAndClassPropertiesMetadata(
	): Typify<NonFunctionProperties<TMetadataHostClass>, PropertyMetadata> {
		return {
			...this.__getPrototypeClassMetadata()?.__mergePrototypeAndClassPropertiesMetadata(),
			...this.properties,
		};
	}

	private __trySetDefaultTableSortProperty(propertyMetadata: PropertyMetadata): void {
		if (!propertyMetadata.defaultSortField
			|| propertyMetadata.property === this.defaultSortProperty?.property)
			return;

		if (this.defaultSortProperty)
			throw new Error(`Only one property can be marked as the default sort field. Existing: ${ this.defaultSortProperty.property }; Attempting: ${ propertyMetadata.property }`);

		// @ts-expect-error we need to init the property here but ts readonly by nature
		this.defaultSortProperty = propertyMetadata;
	}

	private __mirrorCriticalPropertyMetadataToAliasSourceProperty(
		aliasPropertyMetadata: PropertyMetadata,
	): void {
		if (!aliasPropertyMetadata.aliasForPropertyName)
			return;

		this.add(aliasPropertyMetadata.aliasForPropertyName, {
			isSecret: aliasPropertyMetadata.isSecret,
			property: aliasPropertyMetadata.aliasForPropertyName,
		});
	}
}
