import { isBoolean } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';

export class Ability extends Enumeration {

	static enabled = new Ability();

	static disabled = new Ability();

	static override parseHook = (value: any | boolean): Ability | null => {
		if (isBoolean(value))
			return value ? Ability.enabled : Ability.disabled;

		return null;
	};
}
