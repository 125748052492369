import { addBreadcrumb } from '@sentry/angular-ivy';

import { MetaReducer } from '@ngrx/store';

const ACTION_BREADCRUMB_CATEGORY = 'redux.action';
const ACTION_BREADCRUMB_TYPE = 'info';

export function createSentryMetaReducer(): MetaReducer {
	return reducer => (state, action) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const newState = reducer(state, action);

		addBreadcrumb({
			category: ACTION_BREADCRUMB_CATEGORY,
			data: {
				type: action.type,
			},
			type: ACTION_BREADCRUMB_TYPE,
		});

		return newState;
	};
}
