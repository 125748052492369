import { isBoolean } from 'lodash-es';

import { Enumeration } from '@bp/shared/models/core/enum';

export class Activity extends Enumeration {

	static active = new Activity();

	static inactive = new Activity();

	static override parseHook = (value: any | boolean): Activity | null => {
		if (isBoolean(value))
			return value ? Activity.active : Activity.inactive;

		return null;
	};
}
